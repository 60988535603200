import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { ReactComponent as Logo } from '../assets/svg/utti.io.svg'
import styles from '../styles/index.module.scss'

export const pageQuery = graphql`
  query CoolStuffIndexQuery {
    allContentfulCoolStuff(sort: { fields: [publishTime], order: DESC }) {
      edges {
        node {
          title
          slug
          publishTime
          body {
            json
          }
        }
      }
    }
  }
`

function RootIndex(): React.ReactElement {
  return (
    <div className={styles.app}>
      <Logo className={styles.logo} />
    </div>
  )
}

RootIndex.propTypes = {
  data: PropTypes.shape({
    allContentfulCoolStuff: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }),
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
}

export default RootIndex
